import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Grid, Typography, Box } from '@mui/material';
import { auth_api } from './auth_api';
import { useAuth } from './auth_components';

export const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { isAuthenticated, login } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/content');
        }
    }, [isAuthenticated, navigate]);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await auth_api.user_login(email, password);
            login();
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    return (
        <Container maxWidth="md">
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "80vh" }}>
                <Grid item xs={12} md={6} display="flex" justifyContent="center">
                    <Box component="form" onSubmit={handleLogin} width="100%">
                        <Typography variant="h4" component="h2" gutterBottom>
                            Login
                        </Typography>
                        <TextField
                            label="Email address"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Button type="submit" variant="contained" color="primary">
                                Log In
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} display="flex" justifyContent="center">
                    <img src="start.webp" alt="Playing Children" style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Login;
