import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownViewProp {
      text : string,
}

const MarkdownView: React.FC<MarkdownViewProp> = ({text}) => {
      return (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
      );
}

export default MarkdownView;