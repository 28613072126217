import React from 'react';
import { CssBaseline, Toolbar } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { styled} from '@mui/material/styles';

import { Routes, Route } from 'react-router-dom';
import TravelBlog from './features/travel_blog/travel_blog';
import Nav from './features/nav/nav'
import AppHeader from './features/app_header/app_header';
import { AuthProvider, PrivateRoute } from './features/auth/auth_components';
import { Login } from './features/auth/login';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    overflowY: 'scroll',
    height: 'calc(100vh - 64px)',
  }),
}));

const App = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const navOpen = useSelector((state: RootState) => state.nav.open);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <div style={{ display: 'flex' }}>
          <AppHeader drawerWidth={drawerWidth} />
          <Nav drawerWidth={drawerWidth} />
          <Main open={navOpen}>
            <Toolbar />
            <Routes>
              {/* <Route path="/travel-blog" element={<TravelBlog />} />
              <Route path="/" element={<div>Welcome to My Application</div>} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<PrivateRoute component={<TravelBlog />} />} />
              {/* <Route path="*" element={<Navigate to="/content" replace />} /> */}
            </Routes>
          </Main>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
