import { fetch_post_request } from "../../hooks/api";

const API: string = "/api";
const USER = API + "/user"

export const auth_api = {

      user_login: async (email: string, password: string) : Promise<boolean> => {

            console.log("user login: ", email);

            const loginDetails = {
                  email: email,
                  password: password
            };
      
            try {
                  const login_status = await fetch_post_request(USER + '/login', loginDetails);
                  console.log("user login: ", email, " Done");
                  return login_status.is_ok();
            } catch (error) {
                  console.error("Error user_login", error);
            }
            
            return false;
      },

      user_logout: async () => {
            console.log("user logout");

            try {
                  await fetch_post_request(USER + '/logout', '');
            } catch (error) {
                  console.error("Error user_logout", error);
            }
      },

      verify_user_authentication: async () : Promise<boolean> => {

            console.log("verify_user_authentication");

            try {
                  const login_status = await fetch_post_request(USER + '/auth', "")
                  console.log("verify_user_authentication Done");
                  return login_status.is_ok();
            } catch (error) {
                  console.error("Error during login: ", error);
            }
      
            return false;
      }
}
