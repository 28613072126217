// App.tsx
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, InputBase } from '@mui/material';
import { Menu as MenuIcon, Settings as SettingsIcon, Search as SearchIcon } from '@mui/icons-material';
import { styled, alpha} from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { toggleNavOpen } from '../../features/nav/navSlice';
import ThemeButton from '../../features/theme/themeButton';

interface AppHeaderProps {
      drawerWidth : number,
}
    
const createAppBarShift = (drawerWidth: number) => styled(AppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
})<{
      open?: boolean;
}>(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
            }),
      }),
}));
    
const Search = styled('div')(({ theme }) => ({
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
      },
}));
    
const SearchIconWrapper = styled('div')(({ theme }) => ({
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
}));
    
const StyledInputBase = styled(InputBase)(({ theme }) => ({
      color: 'inherit',
      '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                  width: '20ch',
            },
      },
}));
    
const AppHeader: React.FC<AppHeaderProps> = ({drawerWidth}) => {
      const dispatch = useDispatch();
      const navOpen = useSelector((state: RootState) => state.nav.open);
    
      const AppBarShift = createAppBarShift(drawerWidth);
            
      return (
            <AppBarShift position="fixed" open={navOpen}>
                  <Toolbar>
                        <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              onClick={() => { dispatch(toggleNavOpen()) }}
                              edge="start"
                              sx={{ marginRight: 2, ...(navOpen && { display: 'none' }) }}
                        >
                              <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                              My-Archive
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <Search>
                              <SearchIconWrapper>
                                    <SearchIcon />
                              </SearchIconWrapper>
                              <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                              />
                        </Search>
                        <ThemeButton />
                        <IconButton color="inherit">
                              <SettingsIcon />
                        </IconButton>
                  </Toolbar>
            </AppBarShift>
  
      );
}

export default AppHeader;