import { createSlice } from '@reduxjs/toolkit';

interface NavState {
      open: boolean;
}

const initialState: NavState = {
      open: false,
};

const navSlice = createSlice({
      name: 'nav',
      initialState,
      reducers: {
            toggleNavOpen(state) {
                  state.open = !state.open;
            },
            closeNavBar(state) {
                  state.open = false;
            }
      },
});

export default navSlice.reducer
export const { toggleNavOpen, closeNavBar } = navSlice.actions;
