import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentContent, ITravelBlogEntry, TravelBlogEntryObjectLink } from '../../model/my_archive';
import { my_archive } from '../../hooks/api';
import { failed_state, idle_state, loading_state } from '../../utils';
import { link, stat } from 'fs';
import { act } from 'react';
import { RootState } from '../../store';
import { log } from 'console';
interface MyArchiveState {
      entries: ITravelBlogEntry[];
      documents: DocumentContent[];
      status: string;
}

const initialState: MyArchiveState = {
      entries: [],
      documents: [],
      status: idle_state(),
};

export const get_travel_blog_entries = createAsyncThunk<ITravelBlogEntry[], void>('myArchive/getTravelBlogEntries',
      async () => {
            const response = await my_archive.get_travel_blog_list();
            return response.data as ITravelBlogEntry[];
      }
);

export const add_travel_blog_entry = createAsyncThunk<ITravelBlogEntry[], ITravelBlogEntry>('myArchive/addTravelBlogEntry',
      async (entry : ITravelBlogEntry) => {
            const response = await my_archive.add_travel_blog_entry(entry);
            return response.data as ITravelBlogEntry[];
      }
);

export const update_travel_blog_entry = createAsyncThunk<ITravelBlogEntry, ITravelBlogEntry>('myArchive/updateTravelBlogEntry',
      async (entry : ITravelBlogEntry) => {
            const response = await my_archive.update_travel_blog_entry(entry);
            return response.data as ITravelBlogEntry;
      }
);

export const delete_travel_blog_entry_by_id = createAsyncThunk<ITravelBlogEntry[], number>('myArchive/delete_travel_blog_entry_by_id',
      async (entry_id : number) => {
            const response = await my_archive.delete_travel_blog_entry_by_id(entry_id);
            return response.data as ITravelBlogEntry[];
      }
);

export const add_document_travel_blog_entry_id = createAsyncThunk < { entry_id: number, links:TravelBlogEntryObjectLink[]}, { entry_id: number, documents: DocumentContent[] }>('myArchive/add_document_travel_blog_entry_id',
      async ({ entry_id, documents }) => {
            const response = await my_archive.add_documents_to_travel_blog_entry(entry_id, documents);
            return { entry_id, links:response.data as TravelBlogEntryObjectLink[] };
      }
);

export const delete_document_travel_blog_entry_id = createAsyncThunk<{ link: TravelBlogEntryObjectLink, doc_list: TravelBlogEntryObjectLink[] }, TravelBlogEntryObjectLink>('myArchive/delete_document_travel_blog_entry_id',
      async (link : TravelBlogEntryObjectLink) => {
            const response = await my_archive.delete_document_from_travel_blog_entry(link);
            return { link:link, doc_list:response.data as TravelBlogEntryObjectLink[] };
      }
);

export const get_document_by_object_id = createAsyncThunk<DocumentContent, number,{ state: RootState } >('myArchive/get_document_by_object_id',
      async (object_id: number, thunkAPI) => {
            let state: RootState = thunkAPI.getState();
            const doc = state.myArchive.documents.find(doc => doc.id === object_id);
            if (doc == null || doc.content == null || doc.content.length === 0)
            {
                  const documents = await my_archive.get_document_by_object_id(object_id);
                  return documents.data as DocumentContent;
            }

            return doc;
      }
);

const myArchiveSlice = createSlice({
      name: 'theme',
      initialState,
      reducers: {
            setDocuments(state, action: PayloadAction<number[]>) {
                  if (action == null || action.payload == null)
                  {
                        state.documents = [];
                        return;
                  }
                  action.payload.forEach(i => {
                        const index = state.documents.findIndex(d => d.id === i);
                        if (index < 0) {
                              const new_doc: DocumentContent = {
                                    id: i,
                                    content: "",
                                    name: ""
                              };
                              state.documents.push(new_doc);
                        }
                  });

                  state.documents = state.documents.filter(doc => action.payload.find(i => doc.id === i));
            },
      },
      extraReducers: (builder) => {
            builder
                  .addCase(get_travel_blog_entries.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(get_travel_blog_entries.fulfilled, (state, action) => {
                        state.status = idle_state();
                        state.entries = action.payload;
                        state.documents = [];
                  })
                  .addCase(get_travel_blog_entries.rejected, (state) => {
                        state.status = failed_state();
                  })

                  .addCase(add_travel_blog_entry.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(add_travel_blog_entry.fulfilled, (state, action) => {
                        state.status = idle_state();
                        state.entries = action.payload;
                        state.documents = [];
                  })
                  .addCase(add_travel_blog_entry.rejected, (state) => {
                        state.status = failed_state();
                  })

                  .addCase(update_travel_blog_entry.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(update_travel_blog_entry.fulfilled, (state, action) => {
                        state.status = idle_state();
                        if (action.payload === null)
                        {
                              return;
                        }
                        if (state.entries !== null && state.entries !== undefined && state.entries.length > 0) {
                              const index = state.entries.findIndex((element) => element.id === action.payload.id);
                              if (index < 0) {
                                    
                                    return;
                              }
                              state.entries[index] = action.payload;
                        }
                  })
                  .addCase(update_travel_blog_entry.rejected, (state) => {
                        state.status = failed_state();
                  })
            
                  .addCase(delete_travel_blog_entry_by_id.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(delete_travel_blog_entry_by_id.fulfilled, (state, action) => {
                        state.status = idle_state();
                        state.entries = action.payload;
                        state.documents = [];
                  })
                  .addCase(delete_travel_blog_entry_by_id.rejected, (state) => {
                        state.status = failed_state();
                  })

                  .addCase(add_document_travel_blog_entry_id.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(add_document_travel_blog_entry_id.fulfilled, (state, action) => {
                        state.status = idle_state();
                        if (state.entries == null || state.entries.length < 1) {
                              state.documents = [];
                              state.entries = [];
                              return;
                        }

                        const entry_id = action.payload.entry_id;

                        // Find entry index
                        const index = state.entries.findIndex(e => {
                              return e.id === entry_id
                        });
                        if (index < 0) { return; }

                        state.entries[index].images = action.payload.links;
                        console.log("Add Documents entry[", index, "]: ", state.entries[index]);

                        state.documents = state.documents
                              .filter(d =>
                                    state.entries[index].images.find(i => i.object_id === d.id));
                        
                        console.log("Documents: ", state.documents);
                  })
                  .addCase(add_document_travel_blog_entry_id.rejected, (state) => {
                        state.status = failed_state();
                  })

                  .addCase(get_document_by_object_id.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(get_document_by_object_id.fulfilled, (state, action) => {
                        console.log("get_document_by_object_id.fulfilled")
                        state.status = idle_state();
                        let doc = state.documents.find(i => i.id === action.payload.id);
                        if (doc != null) {
                              doc.content = action.payload.content;
                              doc.name = action.payload.name;
                        }
                  })
                  .addCase(get_document_by_object_id.rejected, (state) => {
                        state.status = failed_state();
                  })

                  .addCase(delete_document_travel_blog_entry_id.pending, (state) => {
                        state.status = loading_state();
                        console.log("Reducer async state: ", state.status);
                  })
                  .addCase(delete_document_travel_blog_entry_id.fulfilled, (state, action) => {
                        console.log("get_document_by_object_id.fulfilled")
                        state.status = idle_state();

                        const entry_id = action.payload.link.travel_blog_id;

                        // Find entry index
                        const index = state.entries.findIndex(e => {
                              return e.id === entry_id
                        });
                        if (index < 0) { return; }

                        if (action?.payload.doc_list == null || action.payload.doc_list.length < 1) {
                              state.entries[index].images = [];
                              state.documents = [];
                              return;
                        }

                        state.entries[index].images = action.payload.doc_list;
                        state.documents = state.documents
                              .filter(d =>
                                    state.entries[index].images.find(i => i.object_id === d.id));
                  })
                  .addCase(delete_document_travel_blog_entry_id.rejected, (state) => {
                        state.status = failed_state();
                  })
            
      }
});

export default myArchiveSlice.reducer;
export const {setDocuments} = myArchiveSlice.actions;
