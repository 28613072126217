import React from 'react';
import { Box, Toolbar, IconButton, Drawer, List, ListItemText, ListItemButton } from '@mui/material';
import { Close as CloseIcon} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { toggleNavOpen, closeNavBar } from './navSlice';
import { useNavigate } from 'react-router-dom';

interface navListItem {
      name: string,
      link: string
};

interface NavProps {
      drawerWidth : number,
}

const navItems: navListItem[] = [
      { name: 'Home', link: '/' },
      { name: 'Urlaubs Blog', link: '/travel-blog' },
      { name: 'Fotos', link: '/' },
      { name: 'Dokumente', link: '/' },
];


const Nav: React.FC<NavProps> = ({drawerWidth}) => {
      const navOpen = useSelector((state: RootState) => state.nav.open);
      const dispatch = useDispatch();
      const navigate = useNavigate();

      return (
            <Drawer
                  sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                              width: drawerWidth,
                              boxSizing: 'border-box',
                        },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={navOpen}
            >
                  <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={() => dispatch(closeNavBar())}>
                              <CloseIcon />
                        </IconButton>
                  </Toolbar>
                  <div>
                        <List>
                              {navItems.map((item) => (
                                    <ListItemButton key={item.name} onClick={() => {
                                          dispatch(closeNavBar());
                                          navigate(item.link)
                                    }}>
                                          <ListItemText primary={item.name} />
                                    </ListItemButton>
                              ))}
                        </List>
                  </div>
            </Drawer>
      );
}

export default Nav;