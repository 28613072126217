import { DocumentContent, ITravelBlogEntry, TravelBlogEntryObjectLink } from "../model/my_archive";
import { Result } from "../model/result";

const API: string = "/api/travel";

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
export async function fetch_get_request(address : string) : Promise<Result<any>>
{
      try {
            console.log("Request: ", address);
            
            const response = await fetch(address);
            if (response.ok)
            {
                  const data = await response.json();
                  console.log("Request: ", address, " Done: ", data);
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response ", address);
            }
      }
      catch (error) {
            console.error("Error request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
export async function fetch_post_request(address : string, payload : any) : Promise<Result<any>>
{
      try {
            console.log("Post-Request: ", address, "payload: ", payload);

            const json = JSON.stringify(payload);
            const response = await fetch(address, {
                  method: 'POST',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: json,
                  credentials: 'include'
            });

            if (response.ok)
            {
                  const data = await response.json();

                  console.log("Post-Request: ", address, "payload: ", payload, " Done: ", data);
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response : " , address , " " , response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
export async function fetch_put_request(address: string, payload: any): Promise<Result<any>> {
      try {
            console.log("Put-Request: ", address)

            console.log(payload);
            const json = JSON.stringify(payload);
            const response = await fetch(address, {
                  method: 'PUT',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: json,
                  credentials: 'include'
            });

            if (response.ok) {
                  const data = await response.json();
                  console.log("Put-Request: ", address, " Done: ", data);
                  return Result.ok(data);
            }
            else {
                  console.error("Invalid response : ", address, " ", response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
export async function fetch_delete_request(address : string, payload : any): Promise<Result<any>> {
      try {
            console.log("Delete-Request: ", address)

            const json = JSON.stringify(payload);
            const response = await fetch(address, {
                  method: 'DELETE',
                  headers: {
                        'Content-Type': 'application/json'
                  },
                  body: json,
                  credentials: 'include'
            });

            if (response.ok) {
                  const data = await response.json();
                  console.log("Delete-Request: ", address, " Done: ", data);
                  return Result.ok(data);
            }
            else {
                  console.error("Invalid response : ", address, " ", response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " ", error);
      }

      return Result.failed();
}

/**
 * Führt ein API-Request aus
 * @param API Request addresse
 * @returns Response Objekt
 */
export async function documents_upload_request(address : string, documents : DocumentContent[]) : Promise<Result<any>>
{
      try {
            console.log("documents_upload_request: ", address, " num docs: ", documents.length);

            const formData = new FormData();

            documents.forEach((doc, index) => {
                  const blob = new Blob([doc.content], { type: 'application/octet-stream' });
                  formData.append(`file${index}`, blob, doc.name);
            });

            const response = await fetch(address, {
                  method: 'POST',
                  body: formData,
                  credentials: 'include'
            });

            if (response.ok)
            {
                  const data = await response.json();
                  console.log("Upload-Request: ", address, " Done");
                  return Result.ok(data);      
            }
            else
            {
                  console.error("Invalid response : '" + address + "' " + response.body);
            }
      } catch (error) {
            console.error("Error post-request: ", address, " " ,error);
      }

      return Result.failed();
}

const bufferToBase64 = (buffer: ArrayBuffer): string => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
};

export const my_archive = {

      /**
       * Returns the list of TravelBlog entries in short form
       * 
       * @returns ITravelBlogEntriesShort[]
       */
      get_travel_blog_list: async (): Promise<Result<ITravelBlogEntry[]>> => {
            const result = await fetch_get_request(API + '/list');
            if (result.is_failed()) {
                  return Result.failed();
            }

            const short_entries: ITravelBlogEntry[] = result.data
                  .map((item: any) => {
                        const data: ITravelBlogEntry = {
                              id: item.id.id,
                              text: item.text,
                              title: item.title,
                              date_from: item.date_from,
                              date_to: item.date_to,
                              images: item.images.map((img: any) => {
                                    const i: TravelBlogEntryObjectLink = {
                                          id: img.id.id,
                                          object_id: img.object_id.id,
                                          travel_blog_id: img.travel_blog_id.id,
                                    };
                                    return i;
                              })
                        };
                        return data;
                  });
            
            return Result.ok(short_entries);
      },

      add_travel_blog_entry: async (entry: ITravelBlogEntry): Promise<Result<ITravelBlogEntry[]>> => {
            const result = await fetch_post_request(API + '/', {
                  id: { id: entry.id },
                  title: entry.title,
                  text: entry.text,
                  date_from: entry.date_from,
                  date_to: entry.date_to,
                  images: entry.images
            });

            if (result.is_failed()) {
                  return Result.failed();
            }

            const entries: ITravelBlogEntry[] = result.data.map((item: any) => {
                  const data: ITravelBlogEntry = {
                        id: item.id.id,
                        text: item.text,
                        title: item.title,
                        date_from: item.date_from,
                        date_to: item.date_to,
                        images: []
                  };
                  return data;
            });

            return Result.ok(entries);
      },
      update_travel_blog_entry: async (entry: ITravelBlogEntry): Promise<Result<ITravelBlogEntry>> => {

            const payload = {
                  id: { id: entry.id },
                  title: entry.title,
                  text: entry.text,
                  date_from: entry.date_from,
                  date_to: entry.date_to,
                  images: entry.images.map((img: TravelBlogEntryObjectLink) => {
                        const i = {
                              id: { id: img.id },
                              object_id: { id: img.object_id },
                              travel_blog_id: { id: img.travel_blog_id },

                        };
                        return i;
                  })
            };

            const result = await fetch_put_request(API + '/update', payload);
            if (result.is_failed()) {
                  return Result.failed();
            }
            
            const data: ITravelBlogEntry = {
                  id: result.data.id.id,
                  text: result.data.text,
                  title: result.data.title,
                  date_from: result.data.date_from,
                  date_to: result.data.date_to,
                  images: result.data.images.map((i:any) => {
                        const ii: TravelBlogEntryObjectLink = {
                              id: i.id.id,
                              object_id: i.object_id.id,
                              travel_blog_id: i.travel_blog_id.id
                        };
                        return ii;
                  })
            };
            return Result.ok(data);
      },
      delete_travel_blog_entry_by_id: async (entry_id: number): Promise<Result<ITravelBlogEntry[]>> => {
            const result = await fetch_delete_request(API + '/' + entry_id, '');

            if (result.is_failed()) {
                  return Result.failed();
            }

            const entries: ITravelBlogEntry[] = result.data.map((item: any) => {
                  const data: ITravelBlogEntry = {
                        id: item.id.id,
                        text: item.text,
                        title: item.title,
                        date_from: item.date_from,
                        date_to: item.date_to,
                        images: []
                  };
                  return data;
            });

            return Result.ok(entries);
      },
      add_documents_to_travel_blog_entry : async (entry_id: number, documents : DocumentContent[]): Promise<Result<TravelBlogEntryObjectLink[]>> => {
            const result = await documents_upload_request(API + '/travelBlog/' + entry_id + '/objects', documents);
            if (result.is_failed()) {
                  return Result.failed();
            }
            
            const data = result.data.map((d: any) => {
                  const link : TravelBlogEntryObjectLink ={
                        id: d.id.id,
                        travel_blog_id : d.travel_blog_id.id,
                        object_id : d.object_id.id
                  };
                  return link;
            });            
            return Result.ok(data as TravelBlogEntryObjectLink[]);
      },
      delete_document_from_travel_blog_entry: async (link: TravelBlogEntryObjectLink): Promise<Result<TravelBlogEntryObjectLink[]>> => {
            const result = await fetch_delete_request(API + '/document', {
                  id: { id: link.id },
                  travel_blog_entry_id: { id: link.travel_blog_id },
                  object_id : { id: link.object_id },
            });
            if (result.is_failed()) {
                  return Result.failed();
            }
            
            const data = result.data.map((d: any) => {
                  const link : TravelBlogEntryObjectLink ={
                        id: d.id.id,
                        travel_blog_id : d.travel_blog_id.id,
                        object_id : d.object_id.id
                  };
                  return link;
            });

            return Result.ok(data);
      },

      get_document_by_object_id : async (document_id: number): Promise<Result<DocumentContent>> => {
            
            const result = await fetch_get_request("/api/travel/document/" + document_id );
            if (result.is_failed()) {
                  return Result.failed();
            }
            
            let base64String = '';
            const array = result.data.content as number[];

            for (let i = 0; i < array.length; i++) {
                base64String += String.fromCharCode(array[i]);
            }            
            const data: DocumentContent = {
                  id: result.data.id.id,
                  name: result.data.name,
                  content : base64String
            }
            return Result.ok(data);
      }

}
