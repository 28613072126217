// AuthContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login as loginAction } from './auth_Slice';  // Umbenannt wegen Namenskonflikt
import { RootState } from '../../store';
import { auth_api } from './auth_api';
import { Navigate } from 'react-router-dom';

// Definiere den Typ für den Kontext
interface AuthContextType {
    isAuthenticated: boolean;
    loading: boolean;
    login: () => void;
    logout: () => void;
}

// Definiere den Typ für die Props von AuthProvider
interface AuthProviderProps {
    children: ReactNode;
}

// Definiere den Typ für die Props von PrivateRoute
interface PrivateRouteProps {
    component: JSX.Element;
}

// Initialisiere den Kontext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        checkToken();
    }, []);

    const checkToken = async () => {
        try {
            const is_valid = await auth_api.verify_user_authentication();
            setAuthenticated(is_valid);
        } catch (error) {
            console.error('Token verification failed:', error);
        }
        setLoading(false);
    };

    const login = () => {
        // Du kannst hier auch den Dispatch von Redux verwenden, falls notwendig
        setAuthenticated(true);
    };

    const logout = () => {
        setAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }) => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? component : <Navigate to="/login" />;
};
