import React from 'react';
import { IconButton } from '@mui/material';
import { Brightness4 as Brightness4Icon, Brightness7 as Brightness7Icon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { toggleDarkMode } from './themeSlice';

interface ThemeButtonProps {
};

const ThemeButton: React.FC<ThemeButtonProps> = () => {
      const darkMode = useSelector((state: RootState) => state.theme.darkMode);
      const dispatch = useDispatch();
    
      return (
            <IconButton
                  color="inherit"
                  onClick={() => { dispatch(toggleDarkMode()) }}
            >
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
      );
}

export default ThemeButton;