// TravelBlog.tsx
import React, { useState, useEffect, MouseEventHandler, useCallback } from 'react';
import TagSelect from '../../components/Tag_Select_Box';
import {
      Button,
      Typography,
      TextField,
      IconButton,
      Box,
      Grid,
      List,
      ListItem,
      ListItemText,
      Collapse,
      ListItemButton,
      MenuItem,
      Select,
      Card,
      CardContent,
      CardActions,
      CardHeader,
      SelectChangeEvent
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import { PhotoCamera as PhotoCamera, Add as AddIcon, Remove as RemoveIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import MarkdownView from '../../components/markdown_view';
import { useDispatch, useSelector } from 'react-redux';
import { get_travel_blog_entries, add_travel_blog_entry, update_travel_blog_entry, delete_travel_blog_entry_by_id, add_document_travel_blog_entry_id, get_document_by_object_id, delete_document_travel_blog_entry_id, setDocuments } from '../myArchiveSlice/myArchiveSlice';
import { RootState, AppDispatch } from '../../store';
import { DocumentContent, ITravelBlogEntry } from '../../model/my_archive';

const get_date = (): string => {
      const now = new Date();
            
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert, daher +1
      const day = String(now.getDate()).padStart(2, '0');
            
      return `${year}-${month}-${day}`;
};

const init_entry = (): ITravelBlogEntry => {
      const init: ITravelBlogEntry = {
            id: 0,
            text: "",
            title: "",
            date_from: get_date(),
            date_to: get_date(),
            images: []
      };
      return init;
};

const TravelBlog = () => {
      const dispatch = useDispatch<AppDispatch>();
      const entries: ITravelBlogEntry[] = useSelector((state: RootState) => state.myArchive.entries);
      const documents: DocumentContent[] = useSelector((state: RootState) => state.myArchive.documents);

      const [selected, setSelected] = useState<ITravelBlogEntry>(init_entry());
      
      const [editMode, setEditMode] = useState(false);
      const [show, setShow] = useState(0);
      // const [entries, setEntries] = useState<BlogEntry[]>(initialEntries);
      // const [newEntry, setNewEntry] = useState<BlogEntry>({
      //       id: Date.now(),
      //       title: '',
      //       dateFrom: "",
      //       dateTo: "",
      //       content: '',
      //       images: [],
      // });


      // const readImgae = () => {
      //       if (documents === null || documents === undefined ||
      //             entries === null || entries === undefined ||
      //             selected === null || selected === undefined ||
      //             selected.images === null || selected.images === undefined) {
      //             return;
      //       }
            
      //       let hasLoaded = false;
      //       selected.images.forEach(img => {
      //             if (documents.find((doc) => {
      //                   return doc !== null &&
      //                         doc !== undefined &&
      //                         doc.id === img.id
      //             }) === undefined) {
      //                   hasLoaded = true;
      //                   dispatch(get_document_by_object_id(img.object_id)).then(() => {
      //                         readImgae();
      //                   })
      //                   return;
      //             }
      //       });
      //       if (hasLoaded === false) {
      //             setLoadingImages(false);
      //       }
      // };

      // ======================================================================
      // Selects a new entry and loads all document sets.
      // ======================================================================
      const SelectEntry = useCallback((id: number) => {
            if (editMode) return;
            if (entries == null || entries.length < 1) return;

            if (entries?.length < 0) {
                  setEditMode(true);
                  setSelected(init_entry);
                  return;
            }

            let index = entries?.findIndex(e => e.id === id) || 0;
            if (index < 0) {
                  index = 0;
            }
            let entry = entries[index];

            let loading_list: number[] = [];
            if (!selected) {
                  loading_list = entry?.images?.map(img => img.object_id);
            }
            else {
                  loading_list = entry?.images?.filter(toLoad => selected.images.find(loaded => loaded.object_id === toLoad.object_id) == null).map(toLoad => toLoad.object_id);
            }
            
            console.log("Loading list: ", loading_list);
            setSelected(entry);
            dispatch(setDocuments(entry?.images?.map(img => img.object_id)));
            
            loading_list?.forEach(img => {
                  dispatch(get_document_by_object_id(img));
            });

      }, [editMode, entries, selected, dispatch]);

      // ======================================================================
      // Reload
      // ======================================================================
      useEffect(() => {
            dispatch(get_travel_blog_entries());
      }, [dispatch]);

      useEffect(() => {
            SelectEntry(selected?.id);
      }, [SelectEntry, entries, selected]);

      const saveEntry = () => {
            if (!selected) {
                  return;
            }

            if (selected.id <= 0) {
                  selected.id = 0;
                  dispatch(add_travel_blog_entry(selected));
            }
            else {
                  dispatch(update_travel_blog_entry(selected));
            }
            
      }

      const handleNewEntryChange = (field: keyof ITravelBlogEntry, value: any) => {
            setSelected((prevEntry) => ({ ...prevEntry, [field]: value }));
      };

      const handleRemoveImage = (object_id: number) => {
            const doc_info = selected?.images?.find(d => d.object_id === object_id);
            if (doc_info != null) {
                  dispatch(delete_document_travel_blog_entry_id(doc_info));
            }
      };

      const handleAddEntry = () => {
            setEditMode(true);
            setSelected(init_entry());
            dispatch(setDocuments([]));
      };

      const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
            const id = event.target.value as number;
            SelectEntry(id);
      };

      const handleRemoveEntry = (id: number) => {
            dispatch(delete_travel_blog_entry_by_id(id));
            setEditMode(false);
            if (entries?.length > 0) {
                  SelectEntry(0);
            }
      };

      const bufferToBase64 = (buffer: ArrayBuffer): string => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
      };
      
      const readFileAsArrayBuffer = (file: File): Promise<DocumentContent> => {
            return new Promise((resolve, reject) => {
                  const reader = new FileReader();
                
                  reader.onload = (e: ProgressEvent<FileReader>) => {
                        if (e.target?.result) {

                              const arrayBuffer = e.target.result as ArrayBuffer;
                              const base64String = bufferToBase64(arrayBuffer);
                              //const dataURL = `data:${file.type};base64,${base64String}`;
                              
                              const dc: DocumentContent = {
                                    id: 0,
                                    name: file.name,
                                    content: base64String
                              };
                              resolve(dc);
                        } else {
                              reject(new Error("Failed to read file."));
                        }
                  };
        
                  reader.onerror = () => reject(new Error("File reading failed."));
                  reader.readAsArrayBuffer(file);
            });
      };

      
      const handleFileChange = async (entry_id: number, event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files === null ||
                  event.target.files === undefined) {
                  return;
            }
            const files = event.target.files;

            if (files) {
                  const filePromises = Array.from(files).map(file => readFileAsArrayBuffer(file));
                  try {
                        const new_documents = await Promise.all(filePromises);
                        dispatch(add_document_travel_blog_entry_id({ entry_id, documents: new_documents }));

                  } catch (error) {
                        console.error("Error reading files:", error);
                  }
            }
      };

      return (
            <Box p={3}>
                  {editMode ? (<></>) : (
                        <Select
                              sx={{ width: '100%' }}
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              value={selected.id}
                              onChange={handleSelectChange}
                        >
                              {entries?.map((entry) => (
                                    <MenuItem
                                          key={entry.id}
                                          value={entry.id}
                                          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                                    >
                                          <Typography variant="h6" component="div">
                                                {entry.title}
                                          </Typography>
                                          <Typography variant="body2" component="div">
                                                {entry.date_from} / {entry.date_to}
                                          </Typography>
                                    </MenuItem>
                              ))}
                        </Select>
                  )}
                  {editMode ? (
                        <Box>
                              <Box key={selected.id} mb={3}>
                                    <TextField
                                          label="Title"
                                          value={selected.title}
                                          onChange={(e) => handleNewEntryChange('title', e.target.value)}
                                          fullWidth
                                          margin="normal"
                                    />
                                    {/* <TagSelect /> */}
                                    <Box display="flex" mt={3}>
                                          <TextField
                                                sx={{ marginRight: 2 }}
                                                label="Datum von:"
                                                type="date"
                                                name="date_from"
                                                value={selected.date_from}
                                                variant="outlined"
                                                onChange={(e) => handleNewEntryChange('date_from', e.target.value)}
                                                InputLabelProps={{
                                                      shrink: true,
                                                }}
                                          /><TextField
                                                label="Datum bis:"
                                                type="date"
                                                name="date_to"
                                                value={selected.date_to}
                                                onChange={(e) => handleNewEntryChange('date_to', e.target.value)}
                                                variant="outlined"
                                                InputLabelProps={{
                                                      shrink: true,
                                                }}
                                          />
                                    </Box>
                                    <TextField
                                          label="Text"
                                          value={selected.text}
                                          onChange={(e) => handleNewEntryChange('text', e.target.value)}
                                          fullWidth
                                          multiline
                                          rows={4}
                                          margin="normal"
                                    />
                                    
                                    <Grid container spacing={2}>
                                          {documents?.map((image, index) => (
                                                <Grid item key={index}>
                                                      <img src={`data:image/jpeg;base64,${image.content || ''}`} alt={`image-${index}`} style={{ width: '100px', height: '100px' }} />
                                                      <IconButton onClick={() => handleRemoveImage(image.id)}>
                                                            <DeleteIcon />
                                                      </IconButton>
                                                </Grid>
                                          ))}
                                    </Grid>
                                    {selected.id > 0 ? (
                                          <Box display="flex" alignItems="center" mb={2} mt={4}>
                                                <Button
                                                      variant="contained"
                                                      component="label"
                                                      startIcon={<PhotoCamera />}
                                                >
                                                      Bild hochladen
                                                      <input type="file" hidden onChange={(e) => handleFileChange(selected.id, e)} multiple />
                                                </Button>
                                          </Box>
                                    ) : ''}
                                    <hr />
                              </Box>
                              <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <IconButton onClick={() => {
                                          if (editMode) {
                                                saveEntry();
                                          }
                                          setEditMode(!editMode);
                                    }}>
                                          {editMode ? <SaveIcon /> : <EditIcon />}
                                    </IconButton>
                                    <IconButton onClick={handleAddEntry}>
                                          <DeleteIcon sx={{ color: 'red' }}/>
                                    </IconButton>

                              </Box>
                        </Box>
                  ) : (
                        <Box>
                              <Box key={selected.id} mb={3}>
                                    <MarkdownView text={selected.text} />
                                    <Grid container spacing={2} mt={2} justifyContent="center"
                                          alignItems="center">
                                          {documents?.map((image, index) => (
                                                <Grid item key={index}>
                                                      <div style={{
                                                            width: '100%',
                                                            height: '250px', // Einheitliche Höhe für alle Bilder
                                                            overflow: 'hidden',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                      }}>
                                                            <img
                                                                  src={`data:image/jpeg;base64,${image.content || ''}`}
                                                                  alt={`image-${index}`}
                                                                  style={{
                                                                        width: 'auto',
                                                                        height: '100%',
                                                                        objectFit: 'cover', // Bild wird zugeschnitten, um den Container auszufüllen
                                                                  }}
                                                            />
                                                      </div>
                                                </Grid>
                                          ))}
                                    </Grid>
                              </Box>
                              <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <IconButton onClick={() => {
                                          if (editMode) {
                                                saveEntry();
                                          }
                                          setEditMode(!editMode);
                                    }}>
                                          {editMode ? <SaveIcon /> : <EditIcon />}
                                    </IconButton>
                              </Box>
                        </Box>
                  )}
            </Box>
      );
};

export default TravelBlog;
function state(state: unknown): unknown {
      throw new Error('Function not implemented.');
}

