// store.ts
import { configureStore } from '@reduxjs/toolkit';
import themeSlice from './features/theme/themeSlice';
import navSlice from './features/nav/navSlice';
import myArchiveSlice from './features/myArchiveSlice/myArchiveSlice';
import auth_Slice from './features/auth/auth_Slice';

const store = configureStore({
      reducer: {
            theme: themeSlice,
            nav: navSlice,
            myArchive: myArchiveSlice,
            auth: auth_Slice,
      },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
