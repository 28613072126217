import { createSlice } from '@reduxjs/toolkit';

interface ThemeState {
      darkMode: boolean;
}

const initialState: ThemeState = {
      darkMode: false,
};

const themeSlice = createSlice({
      name: 'theme',
      initialState,
      reducers: {
            toggleDarkMode(state) {
                  state.darkMode = !state.darkMode;
            },
      },
});

export default themeSlice.reducer
export const { toggleDarkMode } = themeSlice.actions;
