export enum State {
      ok,
      failed                 
}

export class Result<T> {
      private _data: T | null;
      private _state: State;

      private constructor() {
            this._data = null;
            this._state = State.ok;
       }
      
      public static ok<T>(data: T): Result<T> {
            let r = new Result<T>();
            r._data = data;
            
            return r;
      }

      public static failed<T>(): Result<T> {
            let r = new Result<T>();
            r._data = null;
            r._state = State.failed;

            return r;
      }

      public get data(): T | null {
            return this._data;
      }

      public get state(): State {
            return this._state;
      }

      is_ok = (): boolean => {
            return (this._state === State.ok) && this._data !== null;
      }

      is_failed = (): boolean => {
            return this._state === State.failed ||
                  this._data === null;
      }
}